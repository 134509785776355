import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useGlobalOptions } from '@corratech/context-provider';
import { Metadata } from './Metadata';
import { useDataLayerAction } from '@corratech/tag-manager';
import { useQuery } from 'react-apollo';
import { getSEODataString } from 'ModulesPath/Seo/Data';

export const SEO = props => {
    const {
        product,
        category,
        cmsPage,
        children,
        allowParams,
        pageTitleOverride,
        pageTypeOverride = '',
        customPages,
        blog,
        categoryItems,
        pageBreadcrumbs
    } = props;
    const { location } = window;
    const projectConfig = useGlobalOptions();
    let { urlBase } = props;
    let processedUrlBase;

    // If URL base was not passed in as a prop, try accessing it from env variables,
    // or fall back to the current page origin
    if (!urlBase) {
        urlBase = process.env.URL_BASE || location.origin;
    }

    // if last character of url base is /, use it without changes
    // if last character is NOT /, add / to the end
    if (urlBase[urlBase.length - 1] === '/') processedUrlBase = urlBase;
    else processedUrlBase = urlBase + '/';

    const getPageTitle = title => {
        // If page title is set explicitly
        if (!!pageTitleOverride) {
            return pageTitleOverride;
        }
        const prefix = getStoreConfig('title_prefix', '');
        const suffix = getStoreConfig('title_suffix', '');
        const separator = getStoreConfig('title_separator', '');
        return `${prefix}${separator}${title}${separator}${suffix}`;
    };

    const getAllowedParams = () => {
        let searchParams = new URLSearchParams(location.search);
        let allowedParams = new URLSearchParams();
        searchParams.forEach((value, key) => {
            if (allowParams.includes(key)) {
                allowedParams.append(key, value);
            }
        });

        return allowedParams.toString() ? `?${allowedParams.toString()}` : '';
    };

    const getCanonicalUrl = (pathName = location.pathname.substr(1)) => {
        return `${processedUrlBase}${pathName}${getAllowedParams()}`;
    };

    const getCanonicalUrlWithoutParams = (
        pathName = location.pathname.substr(1)
    ) => {
        return `${processedUrlBase}${pathName}`;
    };

    const getStoreConfig = (field, defaultValue) => {
        if (typeof projectConfig.storeConfig === 'undefined') {
            return defaultValue;
        }
        if (typeof projectConfig.storeConfig[field] === 'undefined') {
            return defaultValue;
        }
        // We use this function only on string and object fields, so this condition should work fine
        return projectConfig.storeConfig[field] || defaultValue;
    };

    const seoConfig = getStoreConfig('seo_graphql', {
        store_name: '',
        metadata_enabled: false
    });

    let title,
        metaTitle,
        metaDescription,
        metaKeywords,
        canonicalUrl,
        defaultRobots,
        pageType = pageTypeOverride;

    const siteName = 'Elemis';

    if (product) {
        title = getPageTitle(product.name);
        metaTitle = product.meta_title || title;
        metaDescription = product.meta_description;
        metaKeywords = product.meta_keyword;
        canonicalUrl = !!product.canonical_url
            ? getCanonicalUrlWithoutParams(product.canonical_url)
            : getCanonicalUrlWithoutParams(
                  product.url_key + getStoreConfig('product_url_suffix', '')
              );
        pageType = 'product';
    } else if (category) {
        title = getPageTitle(category.name);
        metaTitle = category.meta_title || title;
        metaDescription = category.meta_description;
        metaKeywords = category.meta_keywords;
        canonicalUrl = getCanonicalUrlWithoutParams(category.canonical_url);
        pageType = 'category';
    } else if (cmsPage) {
        title = getPageTitle(cmsPage.title);
        metaTitle = cmsPage.meta_title || title;
        metaDescription = cmsPage.meta_description;
        metaKeywords = cmsPage.meta_keywords;
        defaultRobots = cmsPage.default_robots;
        // We do not use url_key on the home page
        const cmsPageUrlKey = location.pathname === '/' ? '' : cmsPage.url_key;
        canonicalUrl = getCanonicalUrl(cmsPageUrlKey);
        pageType = location.pathname === '/' ? 'home' : 'cms';
    } else if (blog) {
        pageType = blog.page_type;

        if (pageType === 'blog' || pageType === 'blog_tag') {
            title = getPageTitle(blog.title || blog.blog_title);
            metaTitle = blog.meta_title || title;
            metaDescription = blog.meta_description;
            metaKeywords = blog.meta_keywords;
            defaultRobots = blog.default_robots;
        } else {
            metaTitle = siteName;
        }

        canonicalUrl = getCanonicalUrl(blog.page_url);
    } else {
        title = getPageTitle(
            getStoreConfig('default_title', seoConfig.store_name)
        );
        metaTitle = title;
        metaDescription = getStoreConfig('default_description', false);
        metaKeywords = getStoreConfig('default_keywords', false);
        canonicalUrl = getCanonicalUrl();
    }

    let pageName = metaTitle;
    if (typeof customPages[location.pathname] !== 'undefined') {
        pageType = customPages[location.pathname]['pageType'] || 'other';
        pageName = customPages[location.pathname]['pageName'] || metaTitle;
    }

    const dataLayerAction = useDataLayerAction();
    useEffect(() => {
        if (!!pageType) {
            dataLayerAction({
                type: 'BT_PAGE_VIEW_COMPLETE',
                data: {
                    pageDetails: {
                        pageType: pageType,
                        pageUrl: location.pathname
                    },
                    timestamp: Date.now()
                }
            });
            dataLayerAction({
                type: 'PAGE_VIEW_DATA',
                data: {
                    pageName: pageName,
                    pageType: pageType,
                    virtualPagePath: `${window.location.pathname}${window.location.hash}`
                }
            });
        }
    }, [product, category, cmsPage, blog]);

    const { data: { seo_data: { seo_data_string } = {} } = {} } = useQuery(
        getSEODataString,
        {
            fetchPolicy: 'no-cache',
            variables: {
                productId: product?.id,
                categoryId: category?.id
            }
        }
    );

    const isMetadataEnabled =
        !!seo_data_string || (!!seoConfig && seoConfig.metadata_enabled);
    const isHomePage = window.location.pathname === '/' ? true : false;

    return (
        <>
            <Helmet>
                {metaTitle !== siteName && (
                    <>
                        <title>{metaTitle}</title>
                        <meta name="title" content={metaTitle} />
                    </>
                )}
                {!!metaKeywords && metaKeywords !== siteName && (
                    <meta name="keywords" content={metaKeywords} />
                )}
                {!!metaDescription && metaDescription !== siteName && (
                    <meta name="description" content={metaDescription} />
                )}
                {defaultRobots && (
                    <meta name="robots" content={defaultRobots} />
                )}
                <link rel="canonical" href={canonicalUrl} />
                {children}
            </Helmet>
            {isMetadataEnabled && (
                <Metadata
                    categoryItems={categoryItems}
                    seo_data_string={seo_data_string || ''}
                    pageBreadcrumbs={pageBreadcrumbs}
                    product={product}
                    urlBase={urlBase}
                    config={seoConfig || {}}
                    breadcrumbModel={product || category || false}
                    getStoreConfig={getStoreConfig}
                    projectConfig={projectConfig}
                />
            )}
        </>
    );
};

SEO.propTypes = {
    product: PropTypes.object,
    category: PropTypes.object,
    cmsPage: PropTypes.object,
    urlBase: PropTypes.string,
    children: PropTypes.node,
    allowParams: PropTypes.array,
    pageTitleOverride: PropTypes.string,
    customPages: PropTypes.object
};

SEO.defaultProps = {
    allowParams: [],
    customPages: {}
};
