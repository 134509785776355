import React, { useContext, useEffect, useRef } from 'react';
import { CartStore, useGlobalOptions } from '@corratech/context-provider';
import { createScript } from './utils/createScript';
import { useLocation } from 'react-router-dom';

export default function useCartActivities() {
    const options = useGlobalOptions();

    const isInitialMount = useRef(true);

    let location = useLocation();

    const { cartState, dispatch } = useContext(CartStore);

    const config = {
        enable: false
    };

    if (!config.enable) return null;

    const scriptBody = activity => {
        return `(function(){if(typeof _ltk == 'object'){ltkCode();}else{(function (d) {
            if (document.addEventListener) document.addEventListener('ltkAsyncListener', d); else {
            e = document.documentElement; e.ltkAsyncProperty = 0; e.attachEvent('onpropertychange', function (e) {
            if (e.propertyName == 'ltkAsyncProperty') { d(); } }); } })(function(){ltkCode();});}
            function ltkCode(){_ltk_util.ready(function(){
                ${activity}                
            })}})();`;
    };

    useEffect(() => {
        const listrakGlobalEl = document.getElementById('listrak-global');
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            if (document.getElementById('listrak-cart-activities') !== null) {
                document.getElementById('listrak-cart-activities').remove();
            }

            if (cartState.cart.items && cartState.cart.items.length > 0) {
                const getItems = () => {
                    let items = [];
                    cartState.cart.items.map((item, key) => {
                        items.push(
                            `_ltk.SCA.AddItemWithLinks('${item.product.sku}', 
                            ${item.quantity}, 
                            '${item.product.price.regularPrice.amount.value}',
                            '${item.product.name}',
                            '${item.product.thumbnail.url}',
                            '${location.pathname}',
                            );`
                        );
                    });

                    items.push(`_ltk.SCA.Meta1='${cartState.cartId}';`);

                    items.push(`_ltk.SCA.Submit();`);

                    return items.join('');
                };

                const activity = getItems();

                document.body.insertBefore(
                    createScript(
                        scriptBody(activity),
                        'listrak-cart-activities'
                    ),
                    listrakGlobalEl
                );
            } else {
                const activity = `_ltk.SCA.ClearCart();`;
                document.body.insertBefore(
                    createScript(
                        scriptBody(activity),
                        'listrak-cart-activities'
                    ),
                    listrakGlobalEl
                );
            }
        }
    }, [cartState.cart.items]);

    useEffect(() => {
        return () => {
            if (document.getElementById('listrak-cart-activities') !== null) {
                document.getElementById('listrak-cart-activities').remove();
            }
        };
    }, []);
}
