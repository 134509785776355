import React, { useMemo } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import './CartItemOptions.less';

export const CartItemOptions = props => {
    const { options = [], productType, hideOptions } = props;

    const displayOptions = useMemo(() => {
        if (productType === 'BundleCartItem') {
            if (hideOptions) {
                return '';
            } else {
                return options.map(({ label: optionLabel, values, id }) => {
                    const key = `${optionLabel}${id}`;
                    return (
                        <span className={'cart-item-option'} key={key}>
                            <span className="label">{optionLabel}:</span>{' '}
                            {values.map(({ label, quantity }) => {
                                return (
                                    <span className={'cart-item-option'}>
                                        <span className="values">{`${quantity} x ${label}`}</span>{' '}
                                    </span>
                                );
                            })}
                        </span>
                    );
                });
            }
        } else if (productType === 'SimpleCartItem') {
            return (
                <span className={'cart-item-option'}>
                    <span className="values">{options}</span>
                </span>
            )
        } else {
            return options.map(({ option_label, value_label }) => {
                const key = `${option_label}${value_label}`;
                return (
                    <span className={'cart-item-option'} key={key}>
                        <span className="label">{option_label}:</span>{' '}
                        <span className="values">{value_label}</span>
                    </span>
                );
            });
        }
    }, [options]);

    if (displayOptions.length === 0) {
        return null;
    }

    return <div className={'cart-item-options'}>{displayOptions}</div>;
};

CartItemOptions.propTypes = {
    options: arrayOf(
        shape({
            label: string,
            value: string
        })
    )
};
