import React, { useContext, useEffect, useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import cartQuery from './graphql/cartQuery.graphql';
import placeOrderMutation from './graphql/placeOrder.graphql';
import paypalMethodMutation from './graphql/setPaypalPaymentMethod.graphql';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import { useHistory } from 'react-router-dom';
import { SummaryBlock } from '@corratech/checkout/SummaryBlock';
import NewsletterCheckbox from '@corratech/checkout/NewsletterCheckbox/NewsletterCheckbox';
import { useTranslation } from 'react-i18next';
import { Util } from '@magento/peregrine';
import { Button } from 'react-bootstrap';
import { CheckoutAgreements } from '@corratech/checkout/CheckoutAgreements/CheckoutAgreements';
import './PaypalConfirmationPage.less';
import { PrivacyPolicy } from 'ModulesPath/Checkout/PrivacyPolicy';
const storage = new Util.BrowserPersistence();

export const PaypalConfirmationPage = ({
    startSummaryOpen = true,
    dataLayerAction
}) => {
    const [locationSearch, setLocationSearch] = useState(
        window.location.search
    );
    const urlParams = new URLSearchParams(locationSearch);
    const { cartState, dispatch } = useContext(CartStore);
    const [agreementsChecked, setAgreementsChecked] = useState(false);
    const LoadingIndicator = useContext(LoaderStore);
    const history = useHistory();
    const [t] = useTranslation();
    const { data, loading, error } = useQuery(cartQuery, {
        variables: {
            cartId: storage.getItem('cartId'),
            isSignedIn: !!storage.getItem('signin_token')
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.cart
            });
        }
    });

    const [
        setPaypalMethodOnCart,
        {
            data: setPaymentMethodData,
            loading: setMethodLoading,
            error: setMethodError
        }
    ] = useMutation(paypalMethodMutation, {
        variables: {
            cartId: storage.getItem('cartId'),
            paypalPayerId: urlParams.get('PayerID'),
            paypalToken: urlParams.get('token')
        }
    });

    const [
        placeOrder,
        { loading: placeOrderLoading, error: placeOrderError }
    ] = useMutation(placeOrderMutation, {
        variables: {
            cartId: storage.getItem('cartId')
        },
        onCompleted: res => {
            if (!!dataLayerAction) {
                dataLayerAction({
                    type: 'PLACE_ORDER',
                    data: {
                        ...cartState,
                        actionField: res
                    }
                });
            }
            dispatch({
                type: 'PLACED_ORDER',
                placedOrder: res
            });
            history.push('/checkout');
        }
    });

    /**
     * Remvoe token and remove back history
     * to avoid tracking token
     */
    useEffect(() => {
        const crossToken = storage.getItem('cross-verify');
        const payaplToken = urlParams.get('token');

        storage.removeItem('cross-verify');

        if (!crossToken || crossToken !== payaplToken) {
            history.push('/checkout');
        }
    }, []);

    /**
     * Redirect if no items present or
     * no token and payerid detected
     */
    useEffect(() => {
        if (!cartState && !cartState.cart.items.length) {
            history.push('/checkout');
        }
    }, [cartState]);

    useEffect(() => {
        if (setPaymentMethodData) {
            placeOrder();
        }
    }, [setPaymentMethodData]);

    if (loading && !cartState) <LoadingIndicator />;

    if (loading || setMethodLoading || placeOrderLoading) {
        return <LoadingIndicator />;
    } else if (
        (!loading && !data) ||
        error ||
        setMethodError ||
        placeOrderError
    ) {
        if (setMethodError || placeOrderError || error) {
            history.push({
                pathname: '/checkout',
                paypalError: {
                    hasError: true,
                    state: 'danger',
                    message: 'Something wrong happned. Payment canceled',
                    error: [setMethodError, placeOrderError]
                }
            });
        }
        return null;
    } else {
        return (
            <div className={'paypal-confirmation-page'}>
                <div className={'checkout-container container-width'}>
                    <div className="left-column">
                        <h2>{t('Your PayPal Payment Was Successful')}</h2>
                        <CheckoutAgreements
                            parentChecked={agreementsChecked}
                            setParentChecked={setAgreementsChecked}
                        />
                        <NewsletterCheckbox />
                        <PrivacyPolicy privacyPolicyLink={'/privacy-policy'} />
                        <div className={'step-btn-block'}>
                            <Button
                                variant="primary"
                                onClick={setPaypalMethodOnCart}
                            >
                                {t('Place Order')}
                            </Button>
                        </div>
                    </div>
                    <div className={'cart-info'}>
                        {!loading && cartState ? (
                            <SummaryBlock
                                startSummaryOpen={startSummaryOpen}
                                hasLoader={loading && !cartState}
                            />
                        ) : (
                            <LoadingIndicator />
                        )}
                    </div>
                </div>
            </div>
        );
    }
};
