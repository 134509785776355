import React, { useContext } from 'react';
import './Crosssell.less';
import { useQuery } from 'react-apollo';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import crosssellProducts from './Queries/crosssellProducts.graphql';
import { useTranslation } from 'react-i18next';
//import { AddToCart } from '@corratech/add-to-cart';
import { Link } from 'react-router-dom';
const AddToCart = React.lazy(() =>
    import('@corratech/add-to-cart/src/AddToCart')
);

export const Crosssell = () => {
    const [t] = useTranslation();
    const LoadingIndicator = useContext(LoaderStore);
    const { cartState, dispatch } = useContext(CartStore);
    const categoryUrlSuffix = '.html';
    let skus = cartState.cart.items.map(({ product }) => product.sku);

    const { data, loading } = useQuery(crosssellProducts, {
        variables: { skus: skus }
    });

    if (loading) {
        return <LoadingIndicator />;
    }
    let crosssellProductsList = [];
    data.products.items.map(({ crosssell_products }) => {
        if (crosssell_products.length > 0) {
            crosssellProductsList.push(...crosssell_products);
        }
    });

    if (crosssellProductsList.length == 0) {
        return '';
    }
    //Remove duplicate products from list
    crosssellProductsList = crosssellProductsList.filter(
        (ele, ind) =>
            ind === crosssellProductsList.findIndex(elem => elem.id === ele.id)
    );

    return (
        <div className={'cart-crosssell'}>
            <div className={'cart-block-title-block'}>
                <strong
                    className={'cart-block-title'}
                    id="block-crosssell-heading"
                    role="heading"
                    aria-level="2"
                >
                    {t('More Choices')}:
                </strong>
            </div>
            <div
                className="crosssell-block-content"
                aria-labelledby="block-crosssell-heading"
            >
                <ul className={'productsList'}>
                    {crosssellProductsList.map((value, key) => {
                        return (
                            <li key={key}>
                                <div className={'productItemBlock'}>
                                    <div className={'productImageBlock'}>
                                        <Link
                                            to={`/${value.url_key}${categoryUrlSuffix}`}
                                            title={value.name}
                                            className={'productImg-link'}
                                            aria-label={value.name}
                                        >
                                            <span
                                                className={'productImageRatio'}
                                            >
                                                <img
                                                    src={`${value.thumbnail.url}?auto=webp&optimize=high&format=pjpg&crop=1:1&width=200`}
                                                    alt={value.name}
                                                    title={value.name}
                                                    className={'productImg'}
                                                />
                                            </span>
                                        </Link>
                                    </div>

                                    <div className={'product-details'}>
                                        <h2 className={'productName'}>
                                            <Link
                                                to={`/${value.url_key}${categoryUrlSuffix}`}
                                                title={value.name}
                                                className={'productName-link'}
                                            >
                                                {value.name}
                                            </Link>
                                        </h2>
                                        <div className={'productPrice'}>
                                            {value.__typename ===
                                            'ConfigurableProduct' ? (
                                                <span
                                                    className={
                                                        'label-low-inline'
                                                    }
                                                >
                                                    {t('As low as')}
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                            {'£'}
                                            {
                                                value.price_range.minimum_price
                                                    .final_price.value
                                            }
                                        </div>
                                    </div>
                                    {value.__typename === 'SimpleProduct' ? (
                                        <AddToCart
                                            quantity={1}
                                            sku={value.sku}
                                            isConfigProduct={
                                                value.__typename ===
                                                'ConfigurableProduct'
                                            }
                                            parent_sku={value.sku}
                                            isBundled={false}
                                            showTick={false}
                                        />
                                    ) : (
                                        <div className={'add-to-cart'}>
                                            <Link
                                                to={`/${value.url_key}${categoryUrlSuffix}`}
                                                title={value.name}
                                                className={'btn btn-primary'}
                                            >
                                                {t('ADD TO CART')}
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};
